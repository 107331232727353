.App {
  text-align: center;
}

.segment-1 {
  position: relative; /* Ensure the overlay stays within this div */
  height: 100vh;
  background-image: url('../images/landingbackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-height: 1080px; /* Set a max height if needed */
}
.segment-1-body {
  max-width: 1920px;
  margin-left: auto;
  margin-right:auto;
}
.segment-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff6e4; /* Replace with your desired hex code */
  opacity: 0.5; /* Adjust opacity for the overlay */
  z-index: 1; /* Ensure the overlay is above the background */
}

.segment-1-body {
  position: relative; /* Ensure content is above the overlay */
  z-index: 2; /* Ensure content is above the overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; /* Full width for responsiveness */
  
}

.segment-2 {
  background-image: url('../images/yachtpic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height:100vh;
  max-height: 980px;
  justify-content: center;
  
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  
  /* Optional padding if needed */
  padding-top: auto;
  padding-bottom: auto;


}

.segment-2-body {
  max-width: 1920px;
  margin-left: auto;
  margin-right:auto;
  
}

.segment-3 {
  justify-content: center;
  max-height: 980px;
}

.segment-3-body {
  max-width: 1920px;
  height:80vh;
  margin-left: auto;
  margin-right:auto;
}

.segment-4 {
  justify-content: center;
  max-height: 980px;
}

.segment-4-body {
  max-width: 1920px;
  height:80vh;
  margin-left: auto;
  margin-right:auto;
}

/* In your CSS file */
.initial-opacity {
  opacity: 0;
}

.initial-opacity2 {
  opacity: 1;
}


/* For mobile phones */
@media (max-width: 480px) {
  .navbar-left a {
    font-size: 12px; /* Further reduce font size */
  }

  .contact-us-button {
    padding: 6px 10px; /* Further reduce button padding */
    font-size: 12px; /* Further reduce font size */
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out forwards; /* forwards ensures the final state is maintained */
  animation-delay: 0.1s; /* Adjust delay as needed */
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  animation: fadeInDown 0.5s ease-out forwards; /* forwards ensures the final state is maintained */
  animation-delay: 1s; /* Adjust delay as needed */
}

.fade-in-down2 {
  animation: fadeInDown 0.5s ease-out forwards; /* forwards ensures the final state is maintained */
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.scale-in {
  animation: scaleIn 1s ease-out forwards; /* forwards ensures the final state is maintained */
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  opacity: 0; /* Set initial opacity to 0 */
  animation: fadeInUp 1s ease-out forwards; /* Apply the fade-in animation */
}

.card-1 {
  animation-delay: 0.5s; /* Delay for the first card */
}

.card-2 {
  animation-delay: 1s; /* Delay for the second card */
}

.card-3 {
  animation-delay: 1.5s; /* Delay for the third card */
}

@media (max-width: 768px) {
  .cards-container::-webkit-scrollbar {
    display: none;
  }
  .cards-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}